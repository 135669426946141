import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {HomePageModule} from './home/home.module';
import {AuthService} from './services/auth.service';
import {DatabaseService} from './services/database.service';
import {SearchService} from './services/search.service';
import {CustomRouterStateSerializer} from './utils/utils';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {AudioProvider} from './services/audio-provider';
import {CardPopComponent} from './shared/card-pop/card-pop.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {FlexLayoutModule} from "@angular/flex-layout";
import {AuthGuard} from './auth.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VideoProvider} from './services/video-provider';



export const firebaseConfig = {
  apiKey: "AIzaSyDYkQkQPjtaZiwic7KFyYJxddXPD2xCl-M",
  authDomain: "citecpror1.firebaseapp.com",
  databaseURL: "https://citecpror1.firebaseio.com",
  projectId: "citecpror1",
  storageBucket: "citecpror1.appspot.com",
  messagingSenderId: "610775402367"
};
// @ts-ignore
@NgModule({
  declarations: [AppComponent, CardPopComponent],
  entryComponents: [CardPopComponent],
  imports: [
      BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
      HomePageModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      FlexLayoutModule

  ],
  providers: [
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
      AuthService, AudioProvider, VideoProvider, DatabaseService, SearchService,AuthGuard

  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
