import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cp-card',
  templateUrl: './cp-card.component.html',
  styleUrls: ['./cp-card.component.scss'],
})
export class CpCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
