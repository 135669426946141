import {Component, HostListener, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {State} from '../../reducers';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../../services/auth.service';
import {Observable, Subscription} from 'rxjs';
import {
    getCoursesPlaylistLength,
    getRNDPlaylistLength,
    getSolutionsPlaylistLength
} from '../../home/home.selectors';
import {map} from 'rxjs/operators';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

    @Input() showFilters:boolean = true;

    userProfile;
    userLoggedIn;
    selectedTab: string;
    rndPlaylistLength:number = 0;
    solutionsPlaylistLength:number = 0;
    coursesListLength:number = 0;

    isRGBOpen:boolean = false;
    isVAROpen:boolean = false;
    isCategoryBoxOpen: boolean = false;

    rndPlaylistLength$: Observable<number>;
    rndPlaylistLengthSelectionSubscription: Subscription;
    solutionsPlaylistLengthSelection$: Observable<number>;
    solutionsPlaylistLengthSelectionSubscription: Subscription;
    coursesListLengthSelection$: Observable<number>;
    coursesListLengthSelectionSubscription: Subscription;

    constructor(private popoverController: PopoverController, private store: Store<State>,
                private router: Router,private afAuth: AngularFireAuth, private authService:AuthService) {

        this.rndPlaylistLength$ = this.store.pipe(select(getRNDPlaylistLength));
        this.solutionsPlaylistLengthSelection$ = this.store.pipe(select(getSolutionsPlaylistLength));
        this.coursesListLengthSelection$ = this.store.pipe(select(getCoursesPlaylistLength));

    }

    ionViewWillLeave() {
    }

    ngOnInit() {
        this.store.select(fromRoot.getCurrentTab).subscribe((tab) => {
            this.selectedTab = tab;
        });

        this.rndPlaylistLengthSelectionSubscription = this.rndPlaylistLength$
            .pipe(
                map(x => {
                    if(x) this.rndPlaylistLength = x;
                    console.log(x);
                })
            )
            .subscribe();

        this.solutionsPlaylistLengthSelectionSubscription = this.solutionsPlaylistLengthSelection$
            .pipe(
                map(x => {
                    if(x) this.solutionsPlaylistLength = x;
                })
            )
            .subscribe();

        this.coursesListLengthSelectionSubscription = this.coursesListLengthSelection$
            .pipe(
                map(x => {
                    if(x) this.coursesListLength = x;
                })
            )
            .subscribe();

    }


    playVideoList() {
        if (this.selectedTab == 'rnd') {
          //  this.store.dispatch(HomeActions.rndVARSelectionChanged({varSelection: {video: true, audio: false, read: false, all:false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'video', tab: 'rnd' }]);

        } else if (this.selectedTab == 'solutions') {
          //  this.store.dispatch(HomeActions.solutionsVARSelectionChanged({varSelection: { video: true, audio: false, read: false, all:false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'video', tab: 'solutions' }]);

        }
        else if (this.selectedTab == 'courses') {
          //  this.store.dispatch(HomeActions.coursesVARSelectionChanged({varSelection: {video: true, audio: false, read: false, all:false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'video', tab: 'courses' }]);
        }
    }



    playAudioList() {
        if (this.selectedTab == 'rnd') {
           // this.store.dispatch(HomeActions.rndVARSelectionChanged({varSelection: { video: false, audio: true, read: false, all:false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'audio', tab: 'rnd'  }]);

        } else if (this.selectedTab == 'solutions') {
          //  this.store.dispatch(HomeActions.solutionsVARSelectionChanged({varSelection: {video: false, audio: true, read: false, all:false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'audio', tab: 'solutions' }]);
        }
        else if (this.selectedTab == 'courses') {
         //   this.store.dispatch(HomeActions.coursesVARSelectionChanged({varSelection: {video: false, audio: true, read: false, all: false}}));
            this.router.navigate(['/home/manageplaylist',  { media: 'audio', tab: 'courses' }]);
        }

    }

    openPlaylist() {
        if (this.selectedTab == 'rnd') {
            this.router.navigate(['/home/manageplaylist',  { media: 'all', tab: 'rnd' }]);
        } else if (this.selectedTab == 'solutions') {
            this.router.navigate(['/home/manageplaylist',  { media: 'all', tab: 'solutions' }]);
        }
        else if(this.selectedTab == 'courses') {
            this.router.navigate(['/home/manageplaylist',  { media: 'all', tab: 'courses' }]);
        }
    }

    openRGBFilter(ev: Event) {
        this.isRGBOpen = true;
    }

    dismissRGBFilter() {
        this.isRGBOpen =false;
    }

    openVARFilter(ev: Event) {
        this.isVAROpen = true;
    }
    dismissVARFilter() {
        this.isVAROpen =false;
    }
/*
    async presentRGBFilterPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: VarFilterComponent,
            event: ev,
        });
        return await popover.present();
    }

    async dismissRGBPopover() {
        console.log("dismissing");
        await this.popoverController.dismiss();
        console.log("dismissed");
        this.isRGBOpen = false;
    }

 */

    openCategoryFilter($event: MouseEvent) {
        this.isCategoryBoxOpen = true;

    }

    dismissCategoryFilter() {
        this.isCategoryBoxOpen = false;

    }

    ngOnDestroy() {
        this.rndPlaylistLengthSelectionSubscription.unsubscribe();

        this.solutionsPlaylistLengthSelectionSubscription.unsubscribe();

        this.coursesListLengthSelectionSubscription.unsubscribe();
    }










}
