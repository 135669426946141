import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';


import * as _ from 'lodash';

export interface State {
  routerReducer: fromRouter.RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer
};


export const routerState = (state: State) => state.routerReducer;

export const getCurrentTab = createSelector(routerState, (url:fromRouter.RouterReducerState) => (_.get(url, 'state.url') ? _.get(url, 'state.url').split("/",4)[3] : null) );
export const getCurrentParams = createSelector(routerState,(url:fromRouter.RouterReducerState) => (url.state.root.paramMap));

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
