import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { HomePage } from './home.page';
import {HomeRoutingModule} from './home-routing.module';
import {StoreModule} from '@ngrx/store';
import {HomeReducer} from './home.reducer';
import {EffectsModule} from '@ngrx/effects';
import {HomeEffects} from './home.effects';
import {ManagePlaylistComponent} from '../manage-playlist/manage-playlist.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomeRoutingModule,
        StoreModule.forFeature('home', HomeReducer),
        EffectsModule.forFeature([HomeEffects]),
        ReactiveFormsModule,
        SharedModule
    ],

  declarations: [HomePage, ManagePlaylistComponent],
    entryComponents:[],
    exports:[IonicModule]
})
export class HomePageModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: HomePageModule,
            providers: [
            ]
        }
    }
}
