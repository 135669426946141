import { NgModule } from '@angular/core';
import {  RouterModule, Routes } from '@angular/router';
import {HomePage} from './home.page';
import {ManagePlaylistComponent} from '../manage-playlist/manage-playlist.component';

const routes: Routes = [
  {
    path: 'tabs',
    component:HomePage,
    children: [
      {
        path: 'rnd',
        children:[
          {
            path:'',
            loadChildren: './rnd/rnd.module#RndPageModule'
          }
        ]
      },
      {
        path: 'solutions',
        children:[
          {
            path:'',
            loadChildren: './solutions/solutions.module#SolutionsPageModule'
          }
        ]
      },
      {
        path: 'courses',
        children:[
          {
            path:'',
            loadChildren: './courses/courses.module#CoursesPageModule'
          }
        ]
      },
      {
        path: 'qa',
        children:[
          {
            path:'',
            loadChildren: './qa/qa.module#QaPageModule'
          }
        ]
      }
    ]
  },
  {
    path: '',
    redirectTo: '/home/tabs/rnd',
    pathMatch: 'full'
  },
  {
    path: 'manageplaylist',
    component: ManagePlaylistComponent,
    pathMatch: 'full'
  },
  {
    path: 'manageplaylist',
    component: ManagePlaylistComponent,
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
