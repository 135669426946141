export enum ThreadState {
    Initiated,
    Commented,
    moderated,
    locked
}
export interface ThreadModel {
    id?: string;
    authorNickName:string;
    authorThumbnailURL:string;
    message:string;
    status:ThreadState,
    seqNo?: number;
    discussionId?: string;

}
