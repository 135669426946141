import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {State} from './reducers';
import {select, Store} from '@ngrx/store';
import {isLoggedIn} from './home/home.selectors';
import {tap} from 'rxjs/operators';
import {userLoginRequested} from './home/home.actions';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private store: Store<State>, private router:Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        let targetURL = state.url;
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.store.dispatch(userLoginRequested({redirectURL:targetURL}));
                    }
                })
            )
    }

}
