import {createFeatureSelector, createSelector} from '@ngrx/store';
import {HomeState} from './home.reducer';


export const selectHomeState =
    createFeatureSelector<HomeState>('home');


export const isLoggedIn = createSelector(
    selectHomeState,
    home => home.authState.userLoggedIn
);

export const getAuthState = createSelector(
    selectHomeState,
    home => home.authState
);

export const getRNDPlaylistLength = createSelector(
    getAuthState,
    auth => auth.userProfile.rndPlaylist ? auth.userProfile.rndPlaylist.length : 0
);

export const getSolutionsPlaylistLength = createSelector(
    getAuthState,
    auth => auth.userProfile.solutionsPlaylist ? auth.userProfile.solutionsPlaylist.length :0
);

export const getCoursesPlaylistLength = createSelector(
    getAuthState,
    auth => auth.userProfile.coursesList ? auth.userProfile.coursesList.length : 0
);

export const getRNDPlaylist = createSelector(
    getAuthState,
    auth => auth.userProfile.rndPlaylist
);

export const getSolutionsPlaylist = createSelector(
    getAuthState,
    auth => auth.userProfile.solutionsPlaylist
);

export const getCoursesPlaylist = createSelector(
    getAuthState,
    auth => auth.userProfile.coursesList
);

export const getMediaState = createSelector(
    selectHomeState,
    home => home.mediaState
);

export const getCanplay = createSelector(
    selectHomeState,
    home => home.mediaState.canplay
);



export const getTimeSec = createSelector(
    selectHomeState,
    home => home.mediaState.timeSec
);

export const getRndRGBSelection = createSelector(
    selectHomeState,
    home => home.rgbSelectionsState.rndRGBSelection
);

export const getCatSelection = createSelector(
    getAuthState,
    auth => auth.userProfile.categoriesSelectionList
);

export const getCategoriesAuthorized = createSelector(
    getAuthState,
    auth => auth.userProfile.categoriesAuthorised
);



export const getSolutionsRGBSelection = createSelector(
    selectHomeState,
    home => home.rgbSelectionsState.solutionsRGBSelection
);

export const getRndVARSelection = createSelector(
    selectHomeState,
    home => home.varSelectionsState.rndVARSelection
);

export const getSolutionsVARSelection = createSelector(
    selectHomeState,
    home => home.varSelectionsState.solutionsVARSelection
);

export const getCoursesVARSelection = createSelector(
    selectHomeState,
    home => home.varSelectionsState.coursesVARSelection
);

export const getCourseState = createSelector(
    selectHomeState,
    home => home.courseState.courseId
);


export const isLoginTaskCompleted = createSelector(
    selectHomeState,
    home => {
        if (home.authState.userLoggingInTaskComplete) {
            return {taskStatus: home.authState.userLoggingInSuccess, errorMessage: home.authState.userLoginErrorObject};
        }
    }
);

export const isLogutTaskCompleted = createSelector(
    selectHomeState,
    home => {
        if (home.authState.userLoggingOutTaskComplete) {
            return {taskStatus: home.authState.userLoggingOutSuccess, errorMessage: home.authState.userLogoutErrorObject};
        }
    }
);

export const isRegisteringInTaskCompleted = createSelector(
    selectHomeState,
    home => {
        if (home.authState.userRegisteringInComplete) {
            return {taskStatus: home.authState.userRegisteringInSuccess, errorMessage: home.authState.userRegisteringErrorObject};
        }
    }
);

export const isResetPasswordTaskCompleted = createSelector(
    selectHomeState,
    home => {
        if (home.authState.userResetPasswordComplete) {
            return {taskStatus: home.authState.userResetPasswordSuccess, errorMessage: home.authState.userResetPasswordErrorObject}
        }
    }
);
