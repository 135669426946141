import {createAction, props} from '@ngrx/store';
import {User} from '../models/user';
import {ItemModel} from '../models/item.model';
import {RndUpdateModel} from '../models/rndUpdate.model';
import {CourseModel} from '../models/course.model';
import {PlaylistItemModel} from '../models/playlistItemModel';

export const userLoginRequested = createAction(
    '[Login Page] User Login Requested',
    props<{redirectURL: string}>()
);
export const userLoggingInBegun = createAction(
    '[Login Page] User Login Begun',
    props<{ email: string, pswd: string }>()
);

export const userLoggingInTaskCompleted = createAction(
    '[Login Page] User Login Task Completed',
    props<{ successful: boolean, error?: any }>()
);

export const userLoggedInViaCookie = createAction(
    '[Login Page] User Login Via Cookie',
    props<{ userProfile: User }>()
);


export const cookieLoginObserved = createAction(
    '[Cookie Login Observed]  Cookie Observed',
    props<{ userProfile: User }>()
);


export const userNotYetLoggedIn = createAction(
    '[Login Page] User Not Yet Logged In'
);


export const userLoggingOutBegun = createAction(
    '[Login Page] User Logout Begun'
);

export const userLoggingOutTaskCompleted = createAction(
    '[App Logout] User Logout Task Completed',
    props<{ successful: boolean, error?: any }>()
);


export const userRegisteringInBegun = createAction(
    '[Registration Page] User Register Begun',
    props<{ email: string, pswd: string, userProfile: User }>()
);

export const userRegisteringInTaskCompleted = createAction(
    '[Application Registration] User Registration Task Completed',
    props<{ successful: boolean, error?: any }>()
);

export const userResetPasswordBegun = createAction(
    '[Reset Password Page] User Reset password Begun',
    props<{ email: string }>()
);

export const userResetPasswordTaskCompleted = createAction(
    '[Application Reset Password] User Reset Password Task Completed',
    props<{ successful: boolean, error?: any }>()
);


export const rndRGBSelectionChanged = createAction(
    '[ RND RGB] Selection Changed',
    props<{ rgbSelection: { red: boolean, orange: boolean, green: boolean, all:boolean } }>()
);

export const solutionsRGBSelectionChanged = createAction(
    '[ Solutions RGB] Selection Changed',
    props<{ rgbSelection: { orange: boolean, green: boolean , all: boolean} }>()
);

export const rndVARSelectionChanged = createAction(
    '[ RND VAR] Selection Changed',
    props<{ varSelection: { video: boolean, audio: boolean, read: boolean , all:boolean} }>()
);

export const solutionsVARSelectionChanged = createAction(
    '[ Solutions VAR] Selection Changed',
    props<{ varSelection: { video: boolean, audio: boolean, read: boolean, all:boolean } }>()
);

export const coursesVARSelectionChanged = createAction(
    '[ Solutions VAR] Selection Changed',
    props<{ varSelection: { video: boolean, audio: boolean, read: boolean, all:boolean} }>()
);

export const catSelectionChanged = createAction(
    '[ Categories] Selection Changed',
    props<{ catAuthorized:string[], catSelection: string[] }>()
);

export const courseSelected = createAction(
    '[ Course Selected] Selection Changed',
    props<{ course: { courseId:string} }>()
);

export const playlistSelectionStateChanged = createAction(
    '[ Pllaylist Selection] Selection Changed',
    props<{ playlistSelection: { productType: string, playlistType: string, isCourse: boolean, courseId:string} }>()
);

export const rndPlaylistChanged = createAction(
    '[ Rnd Playlist] Selection Changed',
    props<{ playlist: PlaylistItemModel[] }>()
);

export const solutionsPlaylistChanged = createAction(
    '[ Solutions Playlist] Selection Changed',
    props<{ playlist: PlaylistItemModel[] }>()
);

export const coursesListChanged = createAction(
    '[ Courses List] Selection Changed',
    props<{ playlist: CourseModel[] }>()
);

export const mediaCANPLAY = createAction(
    '[ media ] mediaCANPLAY Changed',
    props<{canplay: boolean}>()
);

export const mediaLOADEDMETADATA = createAction(
    '[ media ] mediaLOADEDMETADATA Changed',
    props<{value:boolean, duration:string, durationSec: number}>()
);

export const mediaPLAYING = createAction(
    '[ media ] mediaPLAYING',
    props<{ playing: boolean}>()
);

export const mediaPAUSE = createAction(
    '[ media ] mediaPAUSE',
    props<{playing: boolean}>()
);

export const mediaTIMEUPDATE = createAction(
    '[ media ] mediaTIMEUPDATE',
    props<{time: string, timeSec:number}>()
);

export const mediaLOADSTART = createAction(
    '[ media ] mediaLOADSTART Changed',
    props<{ loadstart: boolean}>()
);

export const mediaRESET = createAction(
    '[ media ] mediaRESET Changed',
    props<{}>()
);


