import { Component, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-card-pop',
  templateUrl: './card-pop.component.html',
  styleUrls: ['./card-pop.component.scss'],
})
export class CardPopComponent implements OnInit {

  productType:string;
  mediaType:string;
  itemId:string;
  constructor(private popover:PopoverController, private navparams:NavParams) { }

  ngOnInit() {
    this.productType = this.navparams.data.productType;
    this.itemId = this.navparams.data.id;
    console.log(this.navparams.data.productType);
    console.log(this.navparams.data.id);

  }

    addToReadlist() {
    //check product type and dispatch corresponding action
      //addToRndReadlist, addToSolutionsReadList, addToCoursesReadlist
      this.popover.dismiss();
    }
    addToAudiolist() {
      //check product type and dispatch corresponding action
      //addToRndAudioilist, addToSolutionsAudioList, addToCoursesAudiolist
    this.popover.dismiss();
  }
    addToVideolist() {
      //check product type and dispatch corresponding action
      //addToRndVideoilist, addToSolutionsVedioList, addToCoursesvideolist
    this.popover.dismiss();
  }

  addToMySelectionAudiolist() {
    // can have any product type and selected course topics
  }

  addToMySelectionVideolist() {
    // can have any product type and selected course topics
  }

  addToMySelectionReadlist() {
    // can have any product type and selected course topics
  }

}
