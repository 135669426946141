import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as HomeActions from '../../home/home.actions';
import * as fromRoot from '../../reducers/index';
import {State} from '../../reducers/index';
import {Observable, Subscription} from 'rxjs';
import {
  getCoursesVARSelection,
  getRndVARSelection,
  getSolutionsVARSelection
} from '../../home/home.selectors';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-var-filter',
  templateUrl: './var-filter.component.html',
  styleUrls: ['./var-filter.component.scss'],
})
export class VarFilterComponent implements OnInit, OnDestroy {
  selectedTab: string;

  audioRndSelected:boolean;
  videoRndSelected :boolean;
  readRndSelected: boolean;
  allVARRndSelected:boolean = true;

  audioSolutionsSelected:boolean;
  videoSolutionsSelected :boolean;
  readSolutionsSelected: boolean;
  allVARSolutionsSelected:boolean = true;

  audioCoursesSelected:boolean;
  videoCoursesSelected :boolean;
  readCoursesSelected: boolean;
  allVARCoursesSelected:boolean = true;



  rndVarSelection$: Observable<{audio:boolean, video:boolean, read:boolean, all:boolean}>;
  rndVarSelectionSubscription: Subscription;

  solutionsVarSelection$: Observable<{audio:boolean, video:boolean, read:boolean, all:boolean}>;
  solutionsVarSelectionSubscription: Subscription;

  coursesVarSelection$: Observable<{audio:boolean, video:boolean, read:boolean, all:boolean}>;
  coursesVarSelectionSubscription: Subscription;

  constructor(private store: Store<State>) {
    this.rndVarSelection$ = this.store.pipe(select(getRndVARSelection));
    this.solutionsVarSelection$ = this.store.pipe(select(getSolutionsVARSelection));
    this.coursesVarSelection$ = this.store.pipe(select(getCoursesVARSelection));

  }

  ngOnInit() {
    this.store.select(fromRoot.getCurrentTab).subscribe((tab) => {this.selectedTab = tab; console.log(tab)});
    this.rndVarSelectionSubscription = this.rndVarSelection$
        .pipe(
            map(x => {
              this.audioRndSelected = x.audio;
              this.videoRndSelected = x.video;
              this.readRndSelected = x.read;
              this.allVARRndSelected = x.all;
              console.log(this.audioCoursesSelected);
            })
        )
        .subscribe();

    this.solutionsVarSelectionSubscription = this.solutionsVarSelection$
        .pipe(
            map(x => {
              this.audioSolutionsSelected = x.audio;
              this.videoSolutionsSelected = x.video;
              this.readSolutionsSelected = x.read;
              this.allVARSolutionsSelected = x.all;
            })
        )
        .subscribe();

    this.coursesVarSelectionSubscription = this.coursesVarSelection$
        .pipe(
            map(x => {
              this.audioCoursesSelected = x.audio;
              this.videoCoursesSelected = x.video;
              this.readCoursesSelected = x.read;
              this.allVARCoursesSelected = x.all;
            })
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.rndVarSelectionSubscription.unsubscribe();
    this.solutionsVarSelectionSubscription.unsubscribe();
    this.coursesVarSelectionSubscription.unsubscribe();

  }


  onAudioRndSelectionChange(event) {
    this.audioRndSelected = true;
    this.videoRndSelected = false;
    this.readRndSelected= false;
    this.allVARRndSelected = false;
    this.store.dispatch(HomeActions.rndVARSelectionChanged( {varSelection:{audio:true, video:false, read:false, all:false}}));
  }

  onVideoRndSelectionChange(event) {
    this.audioRndSelected = false;
    this.videoRndSelected = true;
    this.readRndSelected= false;
    this.allVARRndSelected = false;
    this.store.dispatch(HomeActions.rndVARSelectionChanged( {varSelection:{audio:false, video:true, read:false, all:false}}));

  }
  onReadRndSelectionChange(event) {
    this.audioRndSelected = false;
    this.videoRndSelected = false;
    this.readRndSelected= true;
    this.allVARRndSelected = false;
    this.store.dispatch(HomeActions.rndVARSelectionChanged( {varSelection:{audio:false, video:false, read:true, all:false}}));

  }

  onAllVarRndSelectionChange(event) {
    this.audioRndSelected = false;
    this.videoRndSelected = false;
    this.readRndSelected= false;
    this.allVARRndSelected = true;
    this.store.dispatch(HomeActions.rndVARSelectionChanged( {varSelection:{audio:false, video:false, read:false, all:true}}));

  }

  onAudioSolutionsSelectionChange(event) {
    this.audioSolutionsSelected = true;
    this.videoSolutionsSelected = false;
    this.readSolutionsSelected= false;
    this.allVARSolutionsSelected = false;
    this.store.dispatch(HomeActions.solutionsVARSelectionChanged( {varSelection:{ audio:true, video:false, read:false, all:false}}));

  }

  onVideoSolutionsSelectionChange(event) {
    this.audioSolutionsSelected = false;
    this.videoSolutionsSelected = true;
    this.readSolutionsSelected= false;
    this.allVARSolutionsSelected = false;
    this.store.dispatch(HomeActions.solutionsVARSelectionChanged( {varSelection:{ audio:false, video:true, read:false, all:false}}));

  }

  onReadSolutionsSelectionChange(event) {
    this.audioSolutionsSelected = false;
    this.videoSolutionsSelected = false;
    this.readSolutionsSelected= true;
    this.allVARSolutionsSelected = false;
    this.store.dispatch(HomeActions.solutionsVARSelectionChanged( {varSelection:{ audio:false, video:false, read:true, all:false}}));

  }

  onAllVarSolutionsSelectionChange(event) {
    this.audioCoursesSelected = true;
    this.videoCoursesSelected = false;
    this.readCoursesSelected= false;
    this.allVARCoursesSelected = false;
    this.store.dispatch(HomeActions.solutionsVARSelectionChanged( {varSelection:{ audio:false, video:false, read:false, all:true}}));

  }


  onAudioCoursesSelectionChange(event) {
    this.audioCoursesSelected = true;
    this.videoCoursesSelected = false;
    this.readCoursesSelected= false;
    this.allVARCoursesSelected = false;
    this.store.dispatch(HomeActions.coursesVARSelectionChanged( {varSelection:{audio:true, video:false, read:false, all:false}}));

  }

  onVideoCoursesSelectionChange(event) {
    this.audioCoursesSelected = false;
    this.videoCoursesSelected = true;
    this.readCoursesSelected= false;
    this.allVARCoursesSelected = false;
    this.store.dispatch(HomeActions.coursesVARSelectionChanged( {varSelection:{ audio:false, video:true, read:false, all:false}}));

  }

  onReadCoursesSelectionChange(event) {
    this.audioCoursesSelected = false;
    this.videoCoursesSelected = false;
    this.readCoursesSelected= true;
    this.allVARCoursesSelected = false;
    this.store.dispatch(HomeActions.coursesVARSelectionChanged( {varSelection:{ audio:false, video:false, read:true, all:false}}));

  }

  onAllVarCoursesSelectionChange(event) {
    this.audioCoursesSelected = false;
    this.videoCoursesSelected = false;
    this.readCoursesSelected= false;
    this.allVARCoursesSelected = true;
    this.store.dispatch(HomeActions.coursesVARSelectionChanged( {varSelection:{ audio:false, video:false, read:false, all:true}}));

  }



}
