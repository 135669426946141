/**
 * Created by siva_ on 1/23/2018.
 */


import { Injectable } from '@angular/core';

import {DatabaseService} from './database.service';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';





@Injectable()
export class SearchService {

  keywords:string[] = [];

  constructor( private dbService:DatabaseService) {

    this.dbService.getKeywordsDoc().subscribe((keywords) => {
      this.keywords= [].concat.apply([], keywords);
      this.keywords = [...new Set(this.keywords)];
      console.log(this.keywords);
    });
  }

  search(term:string) {
    return this.dbService.search(term)
  }




  getAutoCompleteKeywords(term) {
    console.log(this.keywords);
    term = term.toLowerCase();
    return of(this.keywords.filter((item) => item.startsWith(term)));

  }
}
