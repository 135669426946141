import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getCategoriesAuthorized, getCatSelection } from '../../home/home.selectors';
import {Observable, Subscription} from 'rxjs';
import {State} from '../../reducers';
import {first, map} from 'rxjs/operators';
import * as HomeActions from '../../home/home.actions';

@Component({
    selector: 'app-cat-filter',
    templateUrl: './cat-filter.component.html',
    styleUrls: ['./cat-filter.component.scss'],
})
export class CatFilterComponent  implements OnInit, OnDestroy {
    categoriesSelection:{val:string, isChecked:boolean}[] = [];
    catAuthorized: string[];

    categoriesAuthorized$: Observable<string[]>;
    categoriesAuthorizedSubscription: Subscription;

    catSelection$: Observable<string[]>;
    catSelectionSubscription: Subscription;


    constructor(private store: Store<State>) {
        this.catSelection$ = this.store.pipe(select(getCatSelection));
        this.categoriesAuthorized$ = this.store.pipe(select(getCategoriesAuthorized));
    }

    ngOnInit() {
        this.categoriesAuthorizedSubscription = this.categoriesAuthorized$
            .pipe(
                map(x => {
                    if(x && (this.categoriesSelection.length == 0)) {
                        this.catAuthorized = [...x];

                        for (let i = 0; i < x.length; i++) {
                            this.categoriesSelection.push({val:x[i], isChecked:true});
                        }
                    }
                    else {
                        console.log("problem - catAuthSubscription");
                    }
                }),
                first()
            )
            .subscribe();

        this.catSelectionSubscription = this.catSelection$
            .pipe(
                map(x => {
                    if(x) {
                        console.log("in catCollectionSubscription-x"+ x);

                        for (let i = 0; i < this.categoriesSelection.length; i++) {
                            if (x.includes(this.categoriesSelection[i].val)) {
                                this.categoriesSelection[i].isChecked = true;
                            } else {
                                this.categoriesSelection[i].isChecked = false;
                            }
                        }
                    }
                    else {
                        console.log("problem - catSelectionSubscription");
                    }

                }),
                first()
            )
            .subscribe();

    }

    ionViewWillEnter(){


    }

    onCatSelectionChanged() {
        let selectedItems:string[] = [];
        for(let i=0; i < this.categoriesSelection.length; i++){
            if(this.categoriesSelection[i].isChecked)
                selectedItems.push(this.categoriesSelection[i].val)
        }
        this.store.dispatch(HomeActions.catSelectionChanged({catAuthorized: this.catAuthorized, catSelection:selectedItems}));
    }

    ionViewWillLeave() {
    }

    ngOnDestroy() {
        this.catSelectionSubscription.unsubscribe();
        this.categoriesAuthorizedSubscription.unsubscribe();
    }


}
