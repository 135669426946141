import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable()
export class VideoProvider {
    private stop$ = new Subject();
    private avObj :any;

    Video() {
        return document.createElement("video");
    }

    constructor() {
        this.avObj = this.Video();
      //  this.avObj.width = 320;
      //  this.avObj.height=240;
        this.avObj.controls=true;
        this.avObj.controlsList="nodownload";
        this.avObj.disablePictureInPicture = true;
    }

    showControls(){
        this.avObj.controls = true;
    }

    hideControls(){
        this.avObj.controls = false;
    }


    private streamObservable(url) {
        let events = [
            'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
        ];

        const addEvents = function(obj, events, handler) {
            events.forEach(event => {
                obj.addEventListener(event, handler);
            });
        };

        const removeEvents = function(obj, events, handler) {
            events.forEach(event => {
                obj.removeEventListener(event, handler);
            });
        };

        return Observable.create(observer => {
            // Play audio
            this.avObj.src = url;
            this.avObj.load();
            this.avObj.play();

            // Media Events
            const handler = (event) => observer.next(event);
            addEvents(this.avObj, events, handler);

            return () => {
                // Stop Playing
                this.avObj.pause();
                this.avObj.currentTime = 0;

                // Remove EventListeners
                removeEvents(this.avObj, events, handler);
            };
        });
    }

    playStream(url) {
        return this.streamObservable(url).pipe(takeUntil(this.stop$));
    }

    play() {
        this.avObj.play();
    }

    pause() {
        this.avObj.pause();
    }

    stop() {
        this.stop$.next();
    }

    seekTo(seconds) {
        this.avObj.currentTime = seconds;
    }

    ended(){

    }

    formatTime(time, format) {
        return moment.utc(time).format(format);
    }
}
