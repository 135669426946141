import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {RgbFilterComponent} from './rgb-filter/rgb-filter.component';
import {VarFilterComponent} from './var-filter/var-filter.component';
import {CatFilterComponent} from './cat-filter/cat-filter.component';
import {CpCardComponent} from './cp-card/cp-card.component';
import {DiscussionUICComponent} from './discussion-uic/discussion-uic.component';
import {QuillModule} from 'ngx-quill';
import {QaUICComponent} from './qa-uic/qa-uic.component';

let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']         ,                                // remove formatting button
    ['link', 'image', 'video']
];

@NgModule({
    declarations: [
        HeaderComponent,
        RgbFilterComponent,
        VarFilterComponent,
        CatFilterComponent,
        CpCardComponent,
        DiscussionUICComponent,
        QaUICComponent
    ],
    exports: [
        HeaderComponent,
        DiscussionUICComponent,
        QaUICComponent,
        CatFilterComponent

    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        QuillModule.forRoot({
            modules: {
                syntax: true,
                toolbar: toolbarOptions
            },
            placeholder: 'Compose your post here ...',

        })
    ]
})
export class SharedModule {
}
