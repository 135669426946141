import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DiscussionModel} from '../../models/discussion.model';
import {ThreadModel, ThreadState} from '../../models/thread.model';
import {DatabaseService} from '../../services/database.service';
import {QuillEditorComponent} from 'ngx-quill';

@Component({
  selector: 'app-discussion-uic',
  templateUrl: './discussion-uic.component.html',
  styleUrls: ['./discussion-uic.component.scss'],
})
export class DiscussionUICComponent implements OnInit {
  @Output() newDiscussionEditorClosed =  new EventEmitter<any>();
  @Input() discussionData:DiscussionModel;

  @Input() new:boolean;


  @ViewChild(QuillEditorComponent,{read:QuillEditorComponent, static:false})
  quillEditorComponent: QuillEditorComponent;

  featuredThreads:ThreadModel[] = [];
  showDiscussionDetails:boolean= false;
  newDiscussionThreadMessage: string = "";
  newDiscussionThreadCreating: boolean = false;

  creatingNewDiscussion:boolean = false;
  private newDiscussionTitle: string="";
  constructor(private dbService:DatabaseService) { }

  ngOnInit() {
    if(this.new) {
      this.creatingNewDiscussion = true;
    }
    else this.reloadDiscussionThreads();

  }

  reloadDiscussionThreads() {
    this.dbService.loadDiscussionThreads(this.discussionData.courseId, this.discussionData.topicId, this.discussionData.id).subscribe(
        (threads) => {
          this.featuredThreads = threads;
        });
  }

  toggleDiscussionDetails() {
    if(this.showDiscussionDetails) this.showDiscussionDetails = false;
    else if(!this.showDiscussionDetails) this.showDiscussionDetails = true;
  }

  newDiscuionThreadRequested() {
    this.newDiscussionThreadCreating = true;
  }

  cancelNewDiscussionThread() {
    this.newDiscussionThreadCreating = false;
    this.newDiscussionThreadMessage = "";
  }

  submitNewDiscussionThread(id: string) {
    console.log(this.newDiscussionThreadMessage);
    let discussionThread:ThreadModel = {message:this.newDiscussionThreadMessage, discussionId:id, authorNickName:'siva', authorThumbnailURL:'', status:ThreadState.Commented};
    this.dbService.saveDiscussionThread(discussionThread, this.discussionData.courseId, id).then(
        () => {
          this.newDiscussionThreadCreating = false;
          this.newDiscussionThreadMessage = "";
          this.reloadDiscussionThreads();
        }
    )
  }

  onEditorCreated() {
   console.log("editor created");

  }

  onEditorSelectionChanged() {
    console.log("editor selection changed");

  }

  onCancelNewDiscussion() {
    this.creatingNewDiscussion = false;
    this.newDiscussionEditorClosed.emit();
    this.reloadDiscussionThreads();
  }
  onSubmitNewDiscussion() {
    console.log("---" + this.discussionData.courseId);
     this.dbService.addDiscussion(
         {
           title:this.newDiscussionTitle,
           summary:"",
           courseId:this.discussionData.courseId
         } ,
         this.discussionData.courseId).then((elm) => {
       let discussionThread:ThreadModel = {message:this.newDiscussionThreadMessage, discussionId:elm.id, authorNickName:'siva', authorThumbnailURL:'', status:ThreadState.Commented};
       this.dbService.saveDiscussionThread(discussionThread, this.discussionData.courseId, elm.id).then(
           () => {
             this.creatingNewDiscussion = false;
             this.newDiscussionEditorClosed.emit();
             this.reloadDiscussionThreads();
           }
       )
     });

  }
}
