import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as HomeActions from '../../home/home.actions';
import * as fromRoot from '../../reducers/index';
import {State} from '../../reducers/index';
import {Observable, Subscription} from 'rxjs';
import {getRndRGBSelection, getSolutionsRGBSelection} from '../../home/home.selectors';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-rgb-filter',
  templateUrl: './rgb-filter.component.html',
  styleUrls: ['./rgb-filter.component.scss'],
})
export class RgbFilterComponent implements OnInit, OnDestroy {
  selectedTab: string;
  redRnDSelected:boolean;
  orangeRnDSelected :boolean;
  greenRnDSelected: boolean;
  allRndSelected:boolean = true;
  orangeSolutionsSelected : boolean;
  greenSolutionsSelected : boolean;
  allSolutionsSelected:boolean;



  rndRgbSelection$: Observable<{red:boolean, orange:boolean, green:boolean, all:boolean}>;
  rndRgbSelectionSubscription: Subscription;

  solutionsRgbSelection$: Observable<{orange:boolean, green:boolean, all:boolean}>;
  solutionsRgbSelectionSubscription: Subscription;

  constructor(private store: Store<State>) {
    this.rndRgbSelection$ = this.store.pipe(select(getRndRGBSelection));
    this.solutionsRgbSelection$ = this.store.pipe(select(getSolutionsRGBSelection));
  }

  ngOnInit() {
    this.store.select(fromRoot.getCurrentTab).subscribe((tab) => {this.selectedTab = tab; console.log(tab)});
    this.rndRgbSelectionSubscription = this.rndRgbSelection$
        .pipe(
            map(x => {
              this.redRnDSelected = x.red;
              this.orangeRnDSelected = x.orange;
              this.greenRnDSelected = x.green;
              this.allRndSelected = x.all;
              console.log(this.allRndSelected);

            })
        )
        .subscribe();

    this.solutionsRgbSelectionSubscription = this.solutionsRgbSelection$
        .pipe(
            map(x => {
              this.orangeSolutionsSelected = x.orange;
              this.greenSolutionsSelected = x.green;
              this.allSolutionsSelected = x.all;

            })
        )
        .subscribe();

  }

  ngOnDestroy() {
    this.rndRgbSelectionSubscription.unsubscribe();
    this.solutionsRgbSelectionSubscription.unsubscribe();
  }


  onRedUpdateSelectionChange(event) {
    this.redRnDSelected = true;
    this.orangeRnDSelected = false;
    this.greenRnDSelected= false;
    this.allRndSelected = false;
    this.store.dispatch(HomeActions.rndRGBSelectionChanged( {rgbSelection:{red:true, orange:false, green:false, all:false}}));
  }

  onOrangeUpdateSelectionChange(event) {
    this.redRnDSelected = false;
    this.orangeRnDSelected = true;
    this.greenRnDSelected= false;
    this.allRndSelected = false;
    this.store.dispatch(HomeActions.rndRGBSelectionChanged( {rgbSelection:{red:false, orange:true, green:false, all:false}}));

  }
  onGreenUpdateSelectionChange(event) {
    this.redRnDSelected = false;
    this.orangeRnDSelected = false;
    this.greenRnDSelected= true;
    this.allRndSelected = false;
    this.store.dispatch(HomeActions.rndRGBSelectionChanged( {rgbSelection:{red:false, orange:false, green:true, all:false}}));

  }

  onAllUpdateSelectionChange(event) {
    this.redRnDSelected = false;
    this.orangeRnDSelected = false;
    this.greenRnDSelected= false;
    this.allRndSelected = true;
    this.store.dispatch(HomeActions.rndRGBSelectionChanged( {rgbSelection:{red:false, orange:false, green:false, all:true}}));

  }

  onOrangeSolutionsSelectionChange(event) {
    this.orangeSolutionsSelected = true;
    this.greenSolutionsSelected = false;
    this.allSolutionsSelected = false;
    this.store.dispatch(HomeActions.solutionsRGBSelectionChanged( {rgbSelection:{ orange:this.orangeSolutionsSelected, green:this.greenSolutionsSelected, all:this.allSolutionsSelected}}));

  }

  onGreenSolutionsSelectionChange(event) {
    this.orangeSolutionsSelected = false;
    this.greenSolutionsSelected = true;
    this.allSolutionsSelected = false;
    this.store.dispatch(HomeActions.solutionsRGBSelectionChanged( {rgbSelection:{ orange:this.orangeSolutionsSelected, green:this.greenSolutionsSelected, all:this.allSolutionsSelected}}));

  }

  onAllSolutionsSelectionChange(event) {
    this.orangeSolutionsSelected = false;
    this.greenSolutionsSelected = false;
    this.allSolutionsSelected = true;
    this.store.dispatch(HomeActions.solutionsRGBSelectionChanged( {rgbSelection:{ orange:this.orangeSolutionsSelected, green:this.greenSolutionsSelected, all:this.allSolutionsSelected}}));

  }



}
