import {Action, createReducer, on} from '@ngrx/store';
import * as HomeActions from './home.actions';
import {AuthStateModel} from './models/auth.model';
import {RGBStateModel} from './models/rgb.model';
import {VARStateModel} from './models/var.model';
import {PlaylistSelectionStateModel} from './models/playlist.selection.model';

export interface HomeState {
    authState: AuthStateModel;
    rgbSelectionsState: RGBStateModel;
    varSelectionsState: VARStateModel;
    playlistSelectionState: PlaylistSelectionStateModel;
    courseState: { courseId: string };
    mediaState: {
        canplay: boolean;

        loadedmetadata: boolean;
        duration:string;
        durationSec:number;

        playing: boolean;
        time: string;
        timeSec: number;

        loadstart: boolean;
    }
}

export const initialState: HomeState = {
    authState: {
        userProfile: {
            categoriesAuthorised: ['Web Development', 'Big Data', 'Semantic Search', 'Machine Learning', 'Cloud Services', 'No Sql Databases'],
            categoriesSelectionList: ['Web Development', 'Big Data', 'Semantic Search', 'Machine Learning', 'Cloud Services', 'No Sql Databases']
        },
        userLoggedIn: undefined,
        userLoggingInBegun: undefined,
        userLoggingInSuccess: undefined,
        userLoggingInFailed: undefined,
        userLoggingInTaskComplete: undefined,
        userLoginErrorObject: undefined,
        userLogoutErrorObject: undefined,

        userLoggingOutBegun: undefined,
        userLoggingOutSuccess: undefined,
        userLoggingOutFailed: undefined,
        userLoggingOutTaskComplete: undefined,

        userRegistered: undefined,
        userRegisteringInBegun: undefined,
        userRegisteringInSuccess: undefined,
        userRegisteringInFailed: undefined,
        userRegisteringInComplete: undefined,
        userResetPasswordBegun: undefined,
        userRegisteringErrorObject: undefined,

        userResetPasswordComplete: undefined,
        userResetPasswordSuccess: undefined,
        userResetPasswordErrorObject: undefined
    },
    rgbSelectionsState: {
        rndRGBSelection: {red: false, orange: false, green: false, all: true},
        solutionsRGBSelection: {orange: false, green: false, all: true}
    },
    varSelectionsState: {
        rndVARSelection: {video: undefined, audio: undefined, read: undefined, all: true},
        solutionsVARSelection: {video: undefined, audio: undefined, read: undefined, all: true},
        coursesVARSelection: {video: undefined, audio: undefined, read: undefined, all: true}

    },
    playlistSelectionState: {
        productType: undefined, playlistType: undefined, isCourse: undefined, courseId: undefined
    },
    courseState: {courseId: undefined},
    mediaState: {
        canplay: undefined,

        loadedmetadata: undefined,
        duration:undefined,
        durationSec:undefined,

        playing: undefined,
        time: undefined,
        timeSec: undefined,

        loadstart: undefined
    }
};

const reducer = createReducer(
    initialState,
    on(HomeActions.userLoggedInViaCookie, (state, action) => {
        return {...state, authState: {...state.authState, userLoggedIn: true, userProfile: action.userProfile}};
    }),
    on(HomeActions.userLoggingInTaskCompleted, (state, action) => {
        if (action.successful) {
            return {
                ...state,
                authState: {...state.authState, userLoggedIn: true, userLoggingInSuccess: true, userLoggingInTaskComplete: true}
            };
        } else {
            return {
                ...state,
                authState: {
                    ...state.authState,
                    userLoggedIn: false,
                    userLoggingInSuccess: false,
                    userLoggingInTaskComplete: true,
                    userLoginErrorObject: action.error
                }
            };
        }
    }),
    on(HomeActions.userNotYetLoggedIn, (state) => {
        return {...state, authState: {...state.authState, userLoggedIn: false}};
    }),
    on(HomeActions.userLoggingOutTaskCompleted, (state, action) => {
        if (action.successful) {
            return {
                ...state,
                authState: {...state.authState, userLoggedIn: false, userLoggingOutSuccess: true, userLoggingOutTaskComplete: true}
            };
        } else {
            return {
                ...state,
                authState: {
                    ...state.authState,
                    userLoggedIn: true,
                    userLoggingOutSuccess: false,
                    userLoggingOutTaskComplete: true,
                    userLogoutErrorObject: action.error
                }
            };
        }
    }),
    on(HomeActions.userResetPasswordTaskCompleted, (state, action) => {
        if (action.successful) {
            return {
                ...state,
                authState: {...state.authState, userLoggedIn: true, userRegistered: true, userResetPasswordTaskCompleted: true}
            };
        } else {
            return {
                ...state,
                authState: {
                    ...state.authState,
                    userLoggedIn: false,
                    userResetPasswordTaskCompleted: true,
                    userResetPasswordErrorObject: action.error
                }
            };
        }
    }),
    on(HomeActions.catSelectionChanged, (state, action) => {
        return {...state,
            authState: {
                ...state.authState,
                userProfile: {...state, categoriesAuthorised: action.catAuthorized, categoriesSelectionList: action.catSelection}
            }
        };
    }),
    on(HomeActions.rndRGBSelectionChanged, (state, action) => {
        return {
            ...state,
            rgbSelectionsState:
                {...state.rgbSelectionsState, rndRGBSelection: action.rgbSelection}
        };
    }),
    on(HomeActions.solutionsRGBSelectionChanged, (state, action) => {
        return {...state, rgbSelectionsState: {...state.rgbSelectionsState, solutionsRGBSelection: action.rgbSelection}};
    }),
    on(HomeActions.rndPlaylistChanged, (state, action) => {
        return {...state, authState: {...state.authState, userProfile: {...state, rndPlaylist: action.playlist}}};
    }),
    on(HomeActions.solutionsPlaylistChanged, (state, action) => {
        return {...state, authState: {...state.authState, userProfile: {...state, solutionsPlaylist: action.playlist}}};
    }),
    on(HomeActions.coursesListChanged, (state, action) => {
        return {...state, authState: {...state.authState, userProfile: {...state, coursesList: action.playlist}}};
    }),
    on(HomeActions.rndVARSelectionChanged, (state, action) => {
        return {
            ...state,
            varSelectionsState:
                {...state.varSelectionsState, rndVARSelection: action.varSelection}
        };
    }),
    on(HomeActions.solutionsVARSelectionChanged, (state, action) => {
        return {...state, varSelectionsState: {...state.varSelectionsState, solutionsVARSelection: action.varSelection}};
    }),
    on(HomeActions.coursesVARSelectionChanged, (state, action) => {
        return {...state, varSelectionsState: {...state.varSelectionsState, coursesVARSelection: action.varSelection}};
    }),
    on(HomeActions.courseSelected, (state, action) => {
        return {...state, courseState: {...state.courseState, courseId: action.course.courseId}};
    }),

    on(HomeActions.mediaCANPLAY, (state, action) => {
        return {...state, mediaState: {...state.mediaState, canplay: action.canplay}};
    }),
    on(HomeActions.mediaLOADEDMETADATA, (state, action) => {
        return {...state, mediaState: {...state.mediaState, loadedmetadata: action.value, duration: action.duration, durationSec: action.durationSec}};
    }),
    on(HomeActions.mediaPLAYING, (state, action) => {
        return {...state, mediaState: {...state.mediaState, playing: action.playing}};
    }),
    on(HomeActions.mediaPAUSE, (state, action) => {
        return {...state, mediaState: {...state.mediaState, playing: action.playing}};
    }),
    on(HomeActions.mediaTIMEUPDATE, (state, action) => {
        return {...state, mediaState: {...state.mediaState, time: action.time, timeSec: action.timeSec}};
    }),
    on(HomeActions.mediaLOADSTART, (state, action) => {
        return {...state, mediaState: {...state.mediaState, loadstart: action.loadstart}};
    }),
    on(HomeActions.mediaRESET, (state, action) => {
        return {...state, mediaState: {...state.mediaState, canplay:undefined, loadedmetadata:undefined, loadstart:undefined, playing:undefined, time:undefined, timeSec:undefined, duration:undefined, durationSec:undefined}};
    }),
);

export function HomeReducer(state, action: Action) {
    return reducer(state, action);
}
