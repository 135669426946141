import {Component, ElementRef, HostListener, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {trigger, state, style, animate, transition } from '@angular/animations';
import {FormControl} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {State} from '../reducers';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {getCoursesPlaylist, getCourseState, getRNDPlaylist, getCanplay, getMediaState, getTimeSec, getSolutionsPlaylist} from '../home/home.selectors';
import {PlaylistItemModel} from '../models/playlistItemModel';
import {AudioProvider} from '../services/audio-provider';
import * as HomeActions from '../home/home.actions';
import {AngularFireStorage} from '@angular/fire/storage';


import {VideoProvider} from '../services/video-provider';
import {CourseModel} from '../models/course.model';
import {IonContent, IonReorderGroup, Platform} from '@ionic/angular';
import {DatabaseService} from '../services/database.service';
import {AuthService} from '../services/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {TopicModel} from '../models/topic.model';
import {DiscussionModel} from '../models/discussion.model';
import {DiscussionUICComponent} from '../shared/discussion-uic/discussion-uic.component';
import {QaModel} from '../models/qa.model';


@Component({
  selector: 'app-manage-playlist',
  templateUrl: './manage-playlist.component.html',
  styleUrls: ['./manage-playlist.component.scss'],
    animations: [
        trigger('showHide', [
            state(
                'active',
                style({
                    opacity: 1
                })
            ),
            state(
                'inactive',
                style({
                    opacity: 0
                })
            ),
            transition('inactive => active', animate('250ms ease-in')),
            transition('active => inactive', animate('250ms ease-out'))
        ]),
        trigger('showHideControlBar', [
            state(
                'true',
                style({
                    opacity: 1
                })
            ),
            state(
                'false',
                style({
                    opacity: 0
                })
            ),
            transition('false => true', animate('250ms ease-in')),
            transition('true => false', animate('250ms ease-out'))
        ])

    ]
})
export class ManagePlaylistComponent implements OnInit, OnDestroy, OnChanges {




    rndPlaylistSelection$: Observable<PlaylistItemModel[]>;
    rndPlaylistSelectionSubscription: Subscription;

    solutionsPlaylistSelection$: Observable<PlaylistItemModel[]>;
    solutionsPlaylistSelectionSubscription: Subscription;

    coursesPlaylistSelection$: Observable<CourseModel[]>;
    coursesPlaylistSelectionSubscription: Subscription;

    getMediaState$: Observable<any>;
    getMediaStateSubscription: Subscription;

    getCanplay$: Observable<boolean>;
    getCanplaySubscription: Subscription;

    getTimeSec$: Observable<number>;
    getTimeSecSubscription: Subscription;

    courseSelection$: Observable<string>;
    courseSelectionSubscription: Subscription;
  playlistType;
  productType;
  rndList:PlaylistItemModel[]=[];
  solutionsList=[];
  coursesList:CourseModel[] = [];


    isAutoPlaySet = false;
    isPlayingSelection:boolean = false;

    resourceNotAvailable:boolean= false;
  //media
    avProvider:any;
    files:any = [];

    filesSelection:{file:string, isChecked:boolean}[]=[];
    selectedFiles:string[] = [];


    seekbar: FormControl = new FormControl("seekbar");

    state: {canplay:boolean, loadedmetadata:boolean,duration:string, durationSec:number, playing:boolean, time:string, timeSec:number, loadstart:boolean} =
        {canplay:undefined, loadedmetadata:undefined, duration:undefined, durationSec:0, playing:undefined, time:undefined, timeSec:0, loadstart:undefined};
    onSeekState: boolean;
    currentFileIndex = 0;
    currentFileSelectionIndex = 0;
    displayFooter: string = "inactive";
    displayContols: boolean=true;
    isVideoExpanded:boolean = false;
    isVolumeMuted:boolean = false;

    displayRndAudioBanner:string ="inactive";

    currentlyPlayingAudioURL = "";
    currentlyPlayingVideoURL = "";

   //selected course details
    course:CourseModel;
    courseTopics:TopicModel[];
    courseDiscussions:DiscussionModel[];
    private courseQAs: QaModel[];

    topicDiscussions:DiscussionModel[];

    selectedCourseId:string;
    selectedTopicId:string;
    private topicsSelected: boolean = true;
    discussionsChatSelected: boolean = false;
    qasSelected: boolean = false;

    // topicDiscussionsClicked = false;
    topicOrDiscssionOrQASelected = "topics";


    @ViewChild(IonContent, {read:IonContent, static:false}) content: IonContent;

    @ViewChild(IonReorderGroup,{read:IonReorderGroup, static:false})
    reorderGroup: IonReorderGroup;


    @ViewChild('videoAreaId', {read: ElementRef, static: true})
    private videoAreaRef: ElementRef;


    @ViewChild('videoControlsBar', {read: ElementRef, static: true})
    private videoControlsBarRef: ElementRef;

    private avUIPlayStatus = "none";
    private mouseoverVideoWindow: boolean =true;
    private windowWidth: number;
    private initalVideoWidth: number;
    private initalVideoHeight: number;
    private initalVideoWidthPx: string;
    private initalVideoHeightPx: string;
    private windowHeight: number;
    private userProfile;
    private playSpecificDocument: boolean = false;
    private questionRequested: boolean = false;
    private discussionRequested: boolean = false;
    private newDiscussionEditorClosed: boolean = undefined;

    constructor(private route:ActivatedRoute, private store:Store<State>,  private router:Router,
                public audioProvider:AudioProvider,private afAuth: AngularFireAuth, public videoProvider:VideoProvider ,
                private afs:AngularFireStorage, private renderer:Renderer2, private authService:AuthService, public platform:Platform, private dbService:DatabaseService) {
        this.rndPlaylistSelection$ = this.store.pipe(select(getRNDPlaylist));
        this.solutionsPlaylistSelection$ = this.store.pipe(select(getSolutionsPlaylist));
        this.coursesPlaylistSelection$ = this.store.pipe(select(getCoursesPlaylist));
        this.courseSelection$ = this.store.pipe(select(getCourseState));
        this.getMediaState$ = this.store.pipe(select(getMediaState));
        this.getCanplay$ = this.store.pipe(select(getCanplay));
        this.getTimeSec$ = this.store.pipe(select(getTimeSec));

    };

  ngOnInit() {

      this.afAuth.authState.subscribe(
          (user) => {
              this.authService.getUserProfile(user.uid).subscribe(
                  (snap) => {
                      this.userProfile = {
                          ...snap.data(),
                          id: user.uid
                      };
                  }
              )
          }
      );

      this.route.paramMap.subscribe(
          (params) => {
              this.playlistType = params.get('media');
              this.productType = params.get('tab');
              if(params.get('idx') && this.productType != 'coursedetails') {
                  this.currentFileIndex = parseInt(params.get('idx'), 10);
                  this.playSpecificDocument = true;
              }
              else if(this.productType == 'coursedetails') {
                  if(params.get('idx')) {
                      this.dbService.loadACourse(params.get('idx')).subscribe(
                          (doc) =>
                          {
                              this.course = {
                                  id:doc.id,
                                  title:doc.data().title,
                                  subTitle:doc.data().subTitle,
                                  imageUrl:doc.data().imageUrl,
                                  seqNo:doc.data().seqNo,
                                  summary:doc.data().summary
                              };
                              this.dbService.loadCourseTopics(this.course.id).subscribe(
                                  (topicItems) => {
                                      this.courseTopics = topicItems;
                                      this.files = this.courseTopics.map((item) => item.videoUrl);
                                      this.setupFilesSelection();
                                      if(this.playlistType != "all") {
                                          if (this.playSpecificDocument) {
                                              this.isAutoPlaySet = false;
                                              this.openFile(this.files[this.currentFileIndex]);
                                          } else {
                                              this.isAutoPlaySet = true;
                                              this.openFile(this.files[0]);
                                          }
                                      }
                                  });

                              this.dbService.loadDiscussions(this.course.id).subscribe(
                                  (discussionItems) => {
                                      console.log(discussionItems);
                                      this.courseDiscussions = discussionItems;
                                  });

                              this.dbService.loadCourseQAs(this.course.id).subscribe(
                                  (qaItems) => {
                                      this.courseQAs = qaItems;
                                  });

                          }
                      );
                  }
              }

              if(this.playlistType == 'audio')
                  this.avProvider = this.audioProvider;
              else if(this.playlistType == 'video') {
                  this.avProvider = this.videoProvider;
                  if(this.platform.ready()) {
                      if(this.platform.width() > 1000)
                    {
                        this.avProvider.avObj.width=480;
                        this.initalVideoWidth = 480;
                        this.initalVideoWidthPx = this.initalVideoWidth + 'px';
                        this.avProvider.avObj.height=360;
                        this.initalVideoHeight = 360;
                        this.initalVideoHeightPx = this.initalVideoHeight + 'px';

                    }
                    else {
                  if( this.platform.is("tablet") ) {
                      console.log("tablet");

                      this.avProvider.avObj.width=480;
                      this.initalVideoWidth = 480;
                      this.avProvider.avObj.height=360;
                      this.initalVideoHeight = 360;
                      this.initalVideoWidthPx = this.initalVideoWidth + 'px';
                      this.initalVideoHeightPx = this.initalVideoHeight + 'px';


                  }
                  else if(this.platform.is("mobile")){
                      console.log("mobile");

                      this.avProvider.avObj.width=320;
                      this.initalVideoWidth = 320;

                      this.avProvider.avObj.height=240;
                      this.initalVideoHeight = 240;
                      this.initalVideoWidthPx = this.initalVideoWidth + 'px';
                      this.initalVideoHeightPx = this.initalVideoHeight + 'px';

                  }

                      }

                      this.avProvider.avObj.controls = false;
                      this.windowWidth = this.platform.width();
                      this.windowHeight = this.platform.height();

                  }
              }
          }
      )


      this.rndPlaylistSelectionSubscription = this.rndPlaylistSelection$
          .pipe(
              map(x => {
                  if(x && this.productType == "rnd")
                    this.setupFilesAndOpenMedia(x);
              })
          )
          .subscribe();

      this.solutionsPlaylistSelectionSubscription = this.solutionsPlaylistSelection$
          .pipe(
              map(x => {
                  if(x && this.productType == "solutions") {
                      this.solutionsList = [...x];
                      if (this.playlistType == 'audio' && this.productType == 'solutions')
                          this.files = this.solutionsList.map((item) => item.item.content.audioURL);
                      if (this.playlistType == 'video' && this.productType == 'solutions')
                          this.files = this.solutionsList.map((item) => item.item.content.videoURL);

                      this.setupFilesAndOpenMedia(x);
                  }
              })
          )
          .subscribe();

      this.coursesPlaylistSelectionSubscription = this.coursesPlaylistSelection$
          .pipe(
              map(x => {
                  if(x && this.productType == "courses") {
                      this.coursesList = [...x];
                  }
              })
          )
          .subscribe();

/*
      this.courseSelectionSubscription = this.courseSelection$
          .pipe(
              map(x => {
                  if(x && this.productType == "coursedetails"){
                  this.selectedCourseId = x;

                  this.course = this.coursesList.filter((course) => course.id === this.selectedCourseId)[0];
                  this.dbService.loadCourseTopics(this.course.id).subscribe(
                      (topicItems) => {
                          this.courseTopics = topicItems;
                      })

                  this.dbService.loadDiscussions(this.course.id).subscribe(
                      (discussionItems) => {
                          console.log(discussionItems);
                          this.courseDiscussions = discussionItems;
                      })


                      this.files = this.courseTopics.map((item) => item.videoUrl);
                      this.setupFilesSelection();
                      if(this.playlistType != "all") {
                          if (this.playSpecificDocument) {
                              this.isAutoPlaySet = false;
                              this.openFile(this.files[this.currentFileIndex]);
                          } else {
                              this.isAutoPlaySet = true;
                              this.openFile(this.files[0]);
                          }
                      }


              }
              })
          )
          .subscribe();
      */


  }

    setupFilesAndOpenMedia(playlist:any[]){

        let list = [...playlist];

        if(this.productType == "rnd")
            this.rndList = list;
        else if(this.productType == "solutions")
            this.solutionsList = list;
        else if(this.productType == "courses")
            this.coursesList = list;

        if(this.playlistType == 'audio')
            this.files = list.map((item) => item.item.content.audioURL);
        else if(this.playlistType == 'video')
            this.files = list.map((item) => item.item.content.videoURL);

        this.setupFilesSelection();
        if(this.playlistType != "all") {
            if (this.playSpecificDocument) {
                this.isAutoPlaySet = false;
                this.openFile(this.files[this.currentFileIndex]);
            } else {
                this.isAutoPlaySet = true;
                this.openFile(this.files[0]);
            }
        }

    }

    removeItemFromPlaylist(idx:number) {

       if(this.productType == "rnd"){
           let list = [...this.rndList];
           list.splice(idx,1);
           this.store.dispatch(HomeActions.rndPlaylistChanged({playlist:[...list]}));
       }
        else if(this.productType == "solutions"){
           let list = [...this.solutionsList];
           list.splice(idx,1);
            this.store.dispatch(HomeActions.solutionsPlaylistChanged({playlist:[...list]}));
        }
        else if(this.productType == "courses"){
           let list = [...this.coursesList];
           list.splice(idx,1);
            this.store.dispatch(HomeActions.coursesListChanged({playlist:[...list]}));
        }
    }

    doReorder(ev: any, productType) {
        if(productType == 'rnd') {
            let itemToMove = this.rndList.splice(ev.detail.from, 1)[0];
            this.rndList.splice(ev.detail.to, 0, itemToMove);
            // worokaround to the bug in reorder adding undefined entry to the list
            for(let i=0; i < this.rndList.length; i++) {
                if(!this.rndList[i]) this.rndList.splice(i,1)
            }
            //-------------------------------------------------------
            ev.detail.complete();
        }
        else if(productType == "solutions"){
            let itemToMove = this.solutionsList.splice(ev.detail.from, 1)[0];
            this.solutionsList.splice(ev.detail.to, 0, itemToMove);
            // worokaround to the bug in reorder adding undefined entry to the list
            for(let i=0; i < this.solutionsList.length; i++) {
                if(!this.solutionsList[i]) this.solutionsList.splice(i,1)
            }
            //-------------------------------------------------------

            ev.detail.complete();
        }
        else if(productType == "courses"){
            let itemToMove = this.coursesList.splice(ev.detail.from, 1)[0];
            this.coursesList.splice(ev.detail.to, 0, itemToMove);
            // worokaround to the bug in reorder adding undefined entry to the list
            for(let i=0; i < this.coursesList.length; i++) {
                if(!this.coursesList[i]) this.coursesList.splice(i,1)
            }
            //-------------------------------------------------------

            ev.detail.complete();
        }
    }

    toggleReorderGroup() {
        this.reorderGroup.disabled = !this.reorderGroup.disabled;
    }

    setupFilesSelection(){
      this.filesSelection = [];
      for (let i = 0; i < this.files.length; i++) {
          this.filesSelection.push({file:this.files[i], isChecked:false});
      }
  }

openCourseDetails(ix:string) {

    this.store.dispatch(HomeActions.courseSelected({course: {courseId: ix}}));
    this.router.navigate(['/home/manageplaylist', {media: 'video', tab: 'coursedetails', idx: ix}]);

}


    ngOnDestroy() {
      this.rndPlaylistSelectionSubscription.unsubscribe();
      this.solutionsPlaylistSelectionSubscription.unsubscribe();
      this.coursesPlaylistSelectionSubscription.unsubscribe();
     // this.courseSelectionSubscription.unsubscribe();
  }

  ngOnChanges() {
      if(this.playlistType === 'video') {
          this.renderer.appendChild(this.videoAreaRef.nativeElement, this.avProvider.avObj);
          this.renderer.setStyle(this.videoControlsBarRef.nativeElement, 'width', '480px');
      }
  }

    ionViewWillEnter() {
        if(this.playlistType === 'video') {
            this.renderer.appendChild(this.videoAreaRef.nativeElement, this.avProvider.avObj);
            this.renderer.setStyle(this.videoControlsBarRef.nativeElement, 'width', '480px');
        }

        if(this.playSpecificDocument && this.productType == "rnd") {
            const specificDocId = document.getElementById('rndItem'+this.currentFileIndex);
            this.content.scrollToPoint(0,specificDocId.offsetHeight+specificDocId.offsetTop);
        }

        this.getMediaStateSubscription = this.getMediaState$
            .pipe(
                map(x => {
                    if(x) {
                    let mediaState = {...x};
                    this.state.canplay = mediaState.canplay;
                    this.state.loadedmetadata = mediaState.loadedmetadata;
                    this.state.duration = mediaState.duration;
                    this.state.durationSec = mediaState.durationSec;
                    this.state.playing = mediaState.playing;
                    this.state.time = mediaState.time;
                    this.state.timeSec = mediaState.timeSec;
                    this.state.loadstart = mediaState.loadstart;
                    }


                })
            )
            .subscribe();

        this.getCanplaySubscription = this.getCanplay$
            .pipe(
                map(x => {
                    if(x) {
                        this.state.canplay = true;
                       // this.displayFooter = 'active';
                      //  this.displayRndAudioBanner = 'inactive';

                    }
                })
            )
            .subscribe();

        this.getTimeSecSubscription = this.getTimeSec$
            .pipe(
                map(x => {
                    if(x) {
                        this.state.timeSec = x;
                        this.seekbar.setValue(x);
                    }
                })
            )
            .subscribe();

    }

    ionViewDidEnter() {

    }

    ionViewWillLeave() {
      this.getMediaStateSubscription.unsubscribe();
      this.getTimeSecSubscription.unsubscribe();
      this.getCanplaySubscription.unsubscribe();
    }


    openFile(file) {
        if(file === "") {
            this.resourceNotAvailable = true;
            if(this.isAutoPlaySet) {
                if (this.currentFileIndex < this.files.length - 1)
                    this.next();
            }
            else if(this.isPlayingSelection)
                if( this.currentFileSelectionIndex < this.selectedFiles.length-1)
                    this.next();
        }
        else {
             console.log("file opening:" + file);
            this.displayFooter = 'active';
            this.displayRndAudioBanner = 'active';

            if (this.playlistType == 'audio')
                this.currentlyPlayingAudioURL = file;
            else if (this.playlistType == 'video') {
                this.currentlyPlayingVideoURL = file;
            }

            if(this.isAutoPlaySet)
                this.currentFileIndex = this.files.indexOf(file);
            else if(this.isPlayingSelection)
                this.currentFileSelectionIndex = this.selectedFiles.indexOf(file);

            let storageRoot:string ="";
            let storageRef;
            let storageFileName :string ="";

            if(this.productType != "coursedetails") {
             storageRoot = file.split("/",2)[0];
             storageRef = this.afs.ref(storageRoot);
             storageFileName = file.split("/",2)[1];
            }
            else if(this.productType == "coursedetails" && this.topicsSelected) {
                storageRoot = "topicsmedia/videos/" + file.split("/",2)[0];
                storageRef = this.afs.ref(storageRoot);
                storageFileName = file.split("/",2)[1];
            }

            storageRef.child(storageFileName).getDownloadURL().subscribe(
                (downloadedUrl) => {
                    this.playStream(downloadedUrl);
                },
                (error) => {
                    console.log("error downloading media url:"+ error);
                    this.resourceNotAvailable = true;
                    if (this.isAutoPlaySet)
                        if(this.currentFileIndex < this.files.length - 1)
                            this.next();
                    else if(this.isPlayingSelection)
                        if(this.currentFileSelectionIndex < this.selectedFiles.length-1)
                            this.next();
                }
            );
        }
    }

    resetState() {
        this.avProvider.stop();
        this.store.dispatch(HomeActions.mediaRESET({}));
    }

    playStream(url) {
        this.resetState();

        this.avProvider.playStream(url).subscribe(event => {
            const audioObj = event.target;

            switch (event.type) {
                case 'canplay': {
                    console.log("canplay received from avProvider");
                    return this.store.dispatch(HomeActions.mediaCANPLAY({canplay: true}));
                }

                case 'loadedmetadata': {
                    console.log("loadedmetadata received from avProvider");
                    return this.store.dispatch(HomeActions.mediaLOADEDMETADATA({value:true, duration:this.avProvider.formatTime(audioObj.duration*1000,'HH:mm:ss'), durationSec:audioObj.duration}));

                }

                case 'playing': {
                    console.log("playing received from avProvider");
                    if(this.playlistType == "video")
                        this.avUIPlayStatus =  "play";
                    return this.store.dispatch(HomeActions.mediaPLAYING({playing:true}));

                }

                case 'pause': {
                    console.log("pause received from avProvider");
                    this.avUIPlayStatus =  "pause";

                    return this.store.dispatch(HomeActions.mediaPLAYING({playing:false}));

                }

                case 'ended': {
                    if(this.isPlayingSelection) {
                        if (this.currentFileSelectionIndex < this.selectedFiles.length - 1)
                            this.next();
                        else {
                            this.isPlayingSelection = false;
                        }
                    }
                    else if(this.isAutoPlaySet) {
                        if (this.currentFileIndex < this.files.length - 1)
                        {
                            console.log("autoplay playing next");
                            this.next();
                        }
                    }
                }

                case 'timeupdate': {
                    console.log("timeupdate received from avProvider");
                    return this.store.dispatch(HomeActions.mediaTIMEUPDATE({time:this.avProvider.formatTime(audioObj.currentTime*1000,'HH:mm:ss'), timeSec:audioObj.currentTime}));

                }

                case 'loadstart': {
                    console.log("loadstart received from avProvider");
                    return this.store.dispatch(HomeActions.mediaLOADSTART({loadstart:true}));

                }
            }
        });
    }

    pause() {
        this.avUIPlayStatus = "pause";

        this.avProvider.pause();
    }

    play() {
        this.avUIPlayStatus = "play";

        this.avProvider.play();
    }

    stop() {
      this.avUIPlayStatus="pause";
        this.avProvider.stop();
    }

    next() {
        if(this.isPlayingSelection) {
            if(this.currentFileSelectionIndex <= this.selectedFiles.length-1) {
                if(this.selectedFiles[this.currentFileSelectionIndex+1] === "") {
                    console.log("url blank .. forwarding to next item");
                    this.currentFileSelectionIndex = this.currentFileSelectionIndex +1;
                    this.next();
                }
                else {
                    this.currentFileSelectionIndex = this.currentFileSelectionIndex + 1;
                    let file = this.selectedFiles[this.currentFileSelectionIndex];
                    if (this.playlistType === 'audio') this.currentlyPlayingAudioURL = file;
                    else if (this.playlistType ==='video') this.currentlyPlayingVideoURL = file;
                    this.openFile(file);
                }
            }
        }
        else {
            console.log("files length:" + this.files.length);
            if(this.currentFileIndex <= this.files.length-1) {
                console.log("playing next");
                if(this.files[this.currentFileIndex+1] === "") {
                    this.currentFileIndex = this.currentFileIndex +1;
                    this.next();
                }
                else {
                    console.log("playing next");
                    this.currentFileIndex = this.currentFileIndex + 1;
                    let file = this.files[this.currentFileIndex];
                    if (this.playlistType == 'audio') this.currentlyPlayingAudioURL = file;
                    else if (this.playlistType == 'video') this.currentlyPlayingVideoURL = file;
                    this.openFile(file);
                }
            }
        }
    }

    previous() {
        if (this.isPlayingSelection) {
            if (this.currentFileSelectionIndex != 0) {
                if (this.selectedFiles[this.currentFileSelectionIndex - 1] === "") {
                    console.log("url blank .. forwarding to prev item");
                    this.currentFileSelectionIndex = this.currentFileSelectionIndex - 1;
                    this.previous();
                } else {

                    this.currentFileSelectionIndex = this.currentFileSelectionIndex - 1;
                    let file = this.selectedFiles[this.currentFileSelectionIndex];
                    if (this.playlistType == 'audio') this.currentlyPlayingAudioURL = file;
                    else if (this.playlistType == 'video') this.currentlyPlayingVideoURL = file;
                    this.openFile(file);
                }
            }
        } else {
            if (this.currentFileIndex != 0) {

                if (this.currentFileIndex <= this.files.length - 1) {
                    console.log("playing pev");
                    if (this.files[this.currentFileIndex - 1] === "") {
                        this.currentFileIndex = this.currentFileIndex - 1;
                        this.previous();
                    } else {
                        this.currentFileIndex = this.currentFileIndex - 1;
                        let file = this.files[this.currentFileIndex];
                        if (this.playlistType == 'audio') this.currentlyPlayingAudioURL = file;
                        else if (this.playlistType == 'video') this.currentlyPlayingVideoURL = file;
                        this.openFile(file);
                    }
                }
            }
        }
    }



    isFirstPlaying() {
      if(this.isPlayingSelection)
          return this.currentFileSelectionIndex == 0 ? true : false;
       else  return this.currentFileIndex == 0 ? true : false;
    }

    isLastPlaying() {
        if(this.isPlayingSelection)
            return (this.currentFileSelectionIndex == this.selectedFiles.length - 1) ? true : false;
        else return (this.currentFileIndex === this.files.length - 1) ? true : false;
    }

    onSeekStart() {
        this.onSeekState = this.state.playing;
        if (this.onSeekState) {
            this.pause();
        }
    }

    onSeekEnd(event) {
        if (this.onSeekState) {
            this.avProvider.seekTo(event.target.value);
            this.play();
        } else {
            this.avProvider.seekTo(event.target.value);
        }
    }



    onFileSelectionChanged() {
      console.log("files selection changed");
      this.selectedFiles = [];
        for(let i=0; i < this.filesSelection.length; i++){
            if(this.filesSelection[i].isChecked) {
                console.log("pushing selected file:" + this.filesSelection[i].file + " into selectedFiles");
                this.selectedFiles.push(this.filesSelection[i].file);
                console.log(this.selectedFiles);
            }
        }
    }

    playSelected() {
        this.isPlayingSelection = true;
        this.openFile(this.selectedFiles[0]);
    }

    onMouseoverVideoScreen() {
        this.mouseoverVideoWindow = true;
    }

    onMouseleaveVideoScreen() {
        this.mouseoverVideoWindow = false;
    }

    toggleVolumeButton() {
        if(this.isVolumeMuted) {
            this.avProvider.avObj.muted = false;
            this.isVolumeMuted = false;
        }
        else {
            this.avProvider.avObj.muted = true;
            this.isVolumeMuted = true;
        }
    }

    toggleExpandContract() {
        if(this.isVideoExpanded) {
            this.avProvider.avObj.width = this.initalVideoWidth;
            this.avProvider.avObj.height = this.initalVideoHeight;
            let widthInPx = this.initalVideoWidth + 'px';
            if (this.productType == "coursedetails") {
            this.renderer.setStyle(this.videoControlsBarRef.nativeElement, 'width', widthInPx);
        }
            this.isVideoExpanded = false;


        }
        else {
            this.avProvider.avObj.width = this.windowWidth;
            this.avProvider.avObj.height = this.windowHeight;
            let widthInPx = this.windowWidth*9/10 + 'px';
            if (this.productType == "coursedetails") {
                this.renderer.setStyle(this.videoControlsBarRef.nativeElement, 'width', widthInPx);
            }
            this.isVideoExpanded = true;
        }

        }

    reorder(productType: string) {

    }

    audioPlaylistRequested(productType: string) {
        this.router.navigate(['/home/manageplaylist',  { media: 'audio', tab: productType  }]);
    }

    videoPlaylistRequested(productType: string) {
        this.router.navigate(['/home/manageplaylist',  { media: 'video', tab: productType  }]);


    }

    audioPlaySpecificDocument(productType: string, idx:number) {
        this.router.navigate(['/home/manageplaylist',  { media: 'audio', tab: productType, idx:idx  }]);

        /*
        this.productType = this.productType;
        this.isAutoPlaySet = false;
        this.isPlayingSelection = false;
          this.playlistType = "audio";
          this.currentFileIndex = idx;
          this.openFile(this.files[idx]);

         */
    }

    videoPlaySpecificDocument(productType: string, idx: number) {

        this.router.navigate(['/home/manageplaylist',  { media: 'video', tab: productType, idx:idx  }]);

        /*
          this.productType = this.productType;
          this.isAutoPlaySet = false;
          this.isPlayingSelection = false;
          this.playlistType = "video";
          this.currentFileIndex = idx;
          this.openFile(this.files[idx]);

         */

    }

    @HostListener('window:beforeunload')
    async savePlayLists(){
      if(this.productType == "rnd")
          await this.authService.updateUserProfile(
              this.userProfile.id,
              {
                  rndPlaylist:this.rndList
              }
          );
        else if(this.productType == "solutions")
            await this.authService.updateUserProfile(
                this.userProfile.id,
                {
                    solutionsPlaylist:this.solutionsList
                }
            );
        else if(this.productType == "rnd")
            await this.authService.updateUserProfile(
                this.userProfile.id,
                {
                    coursesList:this.coursesList
                }
            );

    }

    topicsDiscussionsSegmentChanged(evt: CustomEvent<any>) {
        if (evt.detail.value == "topics") {
            this.topicOrDiscssionOrQASelected = "topics";
            this.topicsSelected = true;
            this.discussionsChatSelected = false;
            this.qasSelected = false;

            this.avProvider.avObj.width=480;
            this.initalVideoWidth = 480;
            this.avProvider.avObj.height=360;
            this.initalVideoHeight = 360;

            this.initalVideoWidthPx = this.initalVideoWidth + 'px';
            this.initalVideoHeightPx = this.initalVideoHeight + 'px';

          //  while(!this.videoAreaRef.nativeElement) {}
        //    this.renderer.appendChild(this.videoAreaRef.nativeElement, this.avProvider.avObj);

            this.openFile(this.files[0]);
        }
        if (evt.detail.value == "discussions") {
            this.avProvider.avObj.width=0;
            this.initalVideoWidth = 0;
            this.avProvider.avObj.height=0;
            this.initalVideoHeight = 0;
            this.initalVideoWidthPx = this.initalVideoWidth + 'px';
            this.initalVideoHeightPx = this.initalVideoHeight + 'px';


        //    this.renderer.removeChild(this.videoAreaRef.nativeElement, this.avProvider.avObj);


            this.topicOrDiscssionOrQASelected = "discussions";
            this.discussionsChatSelected = true;
            this.qasSelected = false;
            this.topicsSelected = false;



        }
        if (evt.detail.value == "qas") {
            this.avProvider.avObj.width=0;
            this.initalVideoWidth = 0;
            this.avProvider.avObj.height=0;
            this.initalVideoHeight = 0;
            this.initalVideoWidthPx = this.initalVideoWidth + 'px';
            this.initalVideoHeightPx = this.initalVideoHeight + 'px';

        //    this.renderer.removeChild(this.videoAreaRef.nativeElement, this.avProvider.avObj);

            this.topicOrDiscssionOrQASelected = "qas";
            this.qasSelected = true;
            this.topicsSelected = false;
            this.discussionsChatSelected = false;

        }
    }

    newQuestionRequested() {
        this.questionRequested = true;
    }

    newDiscussionRequested() {
        this.discussionRequested = true;
        this.newDiscussionEditorClosed = false;

    }

    addQuestion() {
        // open model to create a new question
    }

    addDiscussion() {
        // open model to create a new discussion
    }

    onNewDiscussionEditorClosed() {
        this.newDiscussionEditorClosed = true;
    }
}

