import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';

import { User } from '../models/user';
import {Store} from '@ngrx/store';
import * as HomeActions from '../home/home.actions';
import {HomeState} from '../home/home.reducer';

import 'firebase/auth';
import {auth} from 'firebase';


@Injectable({
  providedIn:  'root'
})
export class AuthService   {

  currentUserID: string;
  user$: Observable<any>;
  userProfile: User = {};
  userLogedIn = false;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,private store: Store<HomeState>) {


    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userLogedIn = true;
        this.currentUserID = user.uid;
         this.getUserProfile(this.currentUserID).subscribe( (snap) => {
           this.userProfile = {
             ...snap.data(),
             id: this.currentUserID
           };
           this.store.dispatch(HomeActions.cookieLoginObserved({userProfile:this.userProfile}));

             console.log(this.userProfile);
         });
      } else {
          this.store.dispatch(HomeActions.userNotYetLoggedIn());

          console.log("user not looged in ...")

        this.currentUserID = undefined;
        this.userProfile = undefined;
      }
    });
  }


  isUserLogedIn() {
      return this.userLogedIn;
  }

  async getUserProfileData(uid) {
      await this.getUserProfile(uid).subscribe(
        (snap) => {
          this.userProfile = {
            ...snap.data(),
            id: this.currentUserID
          };
          console.log(this.userProfile);
        //  return this.userProfile;
        }
   );
      return this.userProfile;
  }

  getCurentUserId() {
    return this.currentUserID;
  }


  async loginWithEmail(email, pswd) {
    let usr = await this.afAuth.auth.signInWithEmailAndPassword(email, pswd);
        await this.getUserProfile(usr.user.uid).subscribe(
            (snap) => {
                this.userProfile = {
                    ...snap.data(),
                    id: this.currentUserID
                };
                return this.userProfile;
            }
        );
  }

  checkIfUserExists(email:string) {

    return this.afs.collection('users',
      ref => ref.where("email", "==", email)).get();
  }

  getUserProfile(uid)  {

    return this.afs.doc(`users/${uid}`).get()
  /*    .subscribe(
      (snap) => {
        this.userProfile = {
          ...snap.data(),
          id: uid
        };
        console.log(this.userProfile);
      }); */
  }

  logout() {
      return this.afAuth.auth.signOut();
  }

  async signUp(email, pswd, userProfile) {
    let userref = await this.afAuth.auth.createUserWithEmailAndPassword(email, pswd);
    let userprofile = await this.createUserProfile(userProfile, userref.user.uid);
    return userprofile;

  }


     private createUserProfile(userData: User, uid: string) {
         this.userProfile =  userData;
         this.userProfile.id = uid;
         const usr = this.afs.collection('users').doc(uid);
         return usr.set(this.userProfile );
    }


   updateUserProfile(uid: string, changes:Partial<User>) {
    const usr = this.afs.collection('users').doc(uid);
    return usr.update(changes);
  }

    sendPasswordResetEmail(emailAddress) {
        return auth().sendPasswordResetEmail(emailAddress)
            .then(() => console.log('email sent'))
            .catch((error) => console.log(error));
    }
}
