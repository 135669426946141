import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QaModel} from '../../models/qa.model';

@Component({
  selector: 'app-qa-uic',
  templateUrl: './qa-uic.component.html',
  styleUrls: ['./qa-uic.component.scss'],
})
export class QaUICComponent implements OnInit {
  @Output() newDiscussionEditorClosed =  new EventEmitter<any>();

  @Input() qaData:QaModel;
  @Input() new:boolean;


  constructor() { }

  ngOnInit() {}

}
