import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, mergeMap, tap} from 'rxjs/operators';
import * as HomeActions from './home.actions';
import {AuthService} from '../services/auth.service';
import {NavController, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';


@Injectable()
export class HomeEffects {
    constructor(private actions$: Actions, private authService: AuthService, private toastCtrl: ToastController, private router: Router,
                public navCtrl: NavController) {
    }

    @Effect()
    cookieLogin$ =
        this.actions$.pipe(
            ofType(HomeActions.cookieLoginObserved),
            map((action) =>
                HomeActions.userLoggedInViaCookie({userProfile: action.userProfile})
            )
        );


    @Effect({dispatch: false})
    loginRequested$ =
        this.actions$.pipe(
            ofType(HomeActions.userLoginRequested),
            tap((action) => {
                this.router.navigate(['/signup',  { redirectURL: action.redirectURL}]);
               // this.router.navigateByUrl('/signup');
                }
            )
        );

    @Effect()
    loginBegun$ =
        this.actions$.pipe(
            ofType(HomeActions.userLoggingInBegun),
            mergeMap((action) =>
                this.authService.loginWithEmail(action.email, action.pswd)
                    .then(() => HomeActions.userLoggingInTaskCompleted({successful: true}))
                    .catch((err) => HomeActions.userLoggingInTaskCompleted({successful: false, error: err}))
            )
        );

    @Effect()
    logoutBegun$ =
        this.actions$.pipe(
            ofType(HomeActions.userLoggingOutBegun),
            mergeMap(() =>
                this.authService.logout()
                    .then(() => HomeActions.userLoggingOutTaskCompleted({successful: true}))
                    .catch((err) => HomeActions.userLoggingOutTaskCompleted({successful: false, error: err}))
            )
        );


    @Effect()
    registeringBegun$ =
        this.actions$.pipe(
            ofType(HomeActions.userRegisteringInBegun),
            mergeMap((action) => this.authService.signUp(action.email, action.pswd, action.userProfile)
                .then(() => HomeActions.userRegisteringInTaskCompleted({successful: true}))
                .catch((err) => HomeActions.userRegisteringInTaskCompleted({successful: false, error: err}))
            )
        );

    @Effect()
    resetPasswordBegun$ =
        this.actions$.pipe(
            ofType(HomeActions.userResetPasswordBegun),
            mergeMap((action) => this.authService.sendPasswordResetEmail(action.email)
                .then(() => HomeActions.userResetPasswordTaskCompleted({successful: true}))
                .catch((err) => HomeActions.userResetPasswordTaskCompleted({successful: false, error: err}))
            )
        );


}
