import {Component, OnDestroy, OnInit} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {State} from './reducers';
import {isLoggedIn} from './home/home.selectors';
import {Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';

import * as HomeActions from './home/home.actions';
import {AuthService} from './services/auth.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  isLoggedIn$: Observable<boolean>;
  isLoggedInSubscription: Subscription;
  isUserLoggedIn :boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router:Router,
    private store: Store<State>,
    private authService:AuthService,
    private breakpointObserver: BreakpointObserver) {
    this.initializeApp();
    this.isLoggedIn$ = store.pipe(select(isLoggedIn));
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {


    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).pipe(first()).subscribe(result => {
      if (result.matches) {
        console.log("HandsetLandscape");

      }
    });
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait]).pipe(first()).subscribe(result => {
      if (result.matches) {
        console.log("HandsetPortrait");

      }
    });

    this.breakpointObserver.observe([
      Breakpoints.TabletLandscape
    ]).pipe(first()).subscribe(result => {
      if (result.matches) {
        console.log("TabletLandscape");

      }
    });
    this.breakpointObserver.observe([
      Breakpoints.TabletPortrait,
    ]).pipe(first()).subscribe(result => {
      if (result.matches) {
       console.log("TabletPortrait");

      }
    });

    this.breakpointObserver.observe([
      Breakpoints.WebLandscape
    ]).pipe(first()).subscribe(result => {
      if (result.matches) {
        console.log("WebLandscape");

      }
    });
    this.breakpointObserver.observe([
      Breakpoints.WebPortrait
    ]).pipe(first()).subscribe(result => {
      if (result.matches) {
        console.log("WebPortrait");

      }
    });


    this.isLoggedInSubscription = this.isLoggedIn$
        .pipe(
            map(x => {
              console.log("appcomponent registered user loggd in:"+x);
              this.isUserLoggedIn = x;
            })
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.isLoggedInSubscription.unsubscribe();
  }
  onLogout() {
    console.log("disapatching logout begun")
    this.store.dispatch(HomeActions.userLoggingOutBegun());
  }
  onLogin() {
    this.store.dispatch(HomeActions.userLoginRequested({redirectURL:''}));
  }

  onSearch() {
    this.router.navigateByUrl('/search');

  }

  onContact(){
    this.router.navigateByUrl('/contact');

  }


}
