import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {RndUpdateModel} from '../models/rndUpdate.model';
import {SolutionsPaperModel} from '../models/solutionsPaper.model';
import {first, map} from 'rxjs/operators';

import {convertSnaps} from './db-utils';
import {AuthService} from './auth.service';
import {TopicModel} from '../models/topic.model';
import {CourseModel} from '../models/course.model';
import {DiscussionModel} from '../models/discussion.model';
import {ThreadModel} from '../models/thread.model';
import {QaModel} from '../models/qa.model';


@Injectable()
export class DatabaseService {

  rndUpdatesData: RndUpdateModel[] = [];
  solutionPapersData: SolutionsPaperModel[] = [];

  rndUpdatesPlaylist: RndUpdateModel[] = [];
  solutionsPapersPlaylist: SolutionsPaperModel[] = [];

  constructor(private afs: AngularFirestore, private authService:AuthService) {
    /*
    this.saveRndUpdate({
      title: 'R & D Update 1', alertLevel: 'red', readAvailable: true,
      listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
      createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
      content: {
        videoURL: '../../../assets/layout/videos/one.mp4',
        audioURL: '../../../assets/layout/videos/one.mp4',
        bookURL: 'book1'
      }
    })
    this.saveSolutionsPaper({
       title: 'Solutions Paper 1', alertLevel: 'red', readAvailable: true,
      listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
      createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
      content: {
        videoURL: '../../../assets/layout/videos/one.mp4',
        audioURL: '../../../assets/layout/videos/one.mp4',
        bookURL: 'book1'
      }
    });
    */

   //this-9 Data().
  }


  saveContactMessage(msg, userID) {
    const path = 'users/' + userID + '/user_communications';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(msg);
  }

  saveVisitorMessage(msg) {
    const path = 'users/' + 'visitor' + '/user_communications';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(msg);
  }
/*
  search(term) {

    return this.afs.collection('rndupdates', ref =>
        ref.where(ref, "==", term))
      .snapshotChanges()
      .pipe(
        map(snaps => convertSnaps<RndUpdateModel>(snaps)),
        first());
  }
  */

    search(term) {
      var kws=  this.afs.collectionGroup('keywords', ref => ref.where('keyword', 'array-contains', term));
      return kws.get()
          .pipe (
                map(sanpShot => sanpShot.docs.map(doc => doc.ref.parent.parent.get()))
            )
    }
  getKeywordsDoc() {
    return this.afs.collectionGroup('keywords').get()
        .pipe (
            map(sanpShot => sanpShot.docs.map(doc => doc.data().keyword))
        );

  }

  indexDocument(docID, keywordsDocObj) {
    return keywordsDocObj.forEach(
        (key) =>  this.afs.collection('rndupdates/'+docID+'/keywords').add({ "keyword":key})
    )
    //return this.afs.doc('keywords/keywordsdoc').update({"keywords": firebase.firestore.FieldValue.arrayUnion(keywordsDocObj)} );
  }

  loadRndUpdates(alertLevel='All', pageNumber=0): Observable<RndUpdateModel[]> {
    if (alertLevel === "Red" || alertLevel == "Orange" || alertLevel == 'Green') {
      return this.afs.collection('rndupdates', ref => ref.where("alertLevel", "==", alertLevel)
          .where('category','array-contains-any', ['Web Development', 'Big Data'])
        .orderBy("createdAt", 'desc')
      )
        .snapshotChanges()
        .pipe(
          map(snaps => convertSnaps<RndUpdateModel>(snaps)),
          first())
    }
   else {
      /*
     this.saveRndUpdate({
       id: 'R & D Update 1', title: 'R & D Update 1', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
        createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
         videoURL: 'rndvideo/one.mp4',
         audioURL: 'rndaudio/one.mp3',
         bookURL: 'book1'
       }
     });

     this.saveRndUpdate({
       id: 'R & D Update 2', title: 'R & D Update 2', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
           videoURL: 'rndvideo/two.mp4',
         audioURL: 'rndaudio/two.mp3',
         bookURL: 'book2'
       }
     });
     this.saveRndUpdate({
       id: 'R & D Update 3', title: 'R & D Update 3', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
           videoURL: 'rndvideo/three.mp4',
         audioURL: 'rndaudio/three.wav',
         bookURL: 'book3'
       }
     });
     this.saveRndUpdate({
       id: 'R & D Update 4', title: 'R & D Update 4', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
         videoURL: 'rndvideo/four.mp4',
         audioURL: 'rndaudio/four.wav',
         bookURL: 'book4'
       }
     });
     this.saveRndUpdate({
       id: 'R & D Update 5', title: 'R & D Update 5', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
           videoURL: 'rndvideo/five.mp4',
         audioURL: 'rndaudio/five.wav',
         bookURL: 'book5'
       }
     });
     this.saveRndUpdate({
       id: 'R & D Update 6', title: 'R & D Update 6', alertLevel: 'orange', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       createdAt:firebase.firestore.Timestamp.fromDate(new Date()),
       content: {
         videoURL: 'rndvideo/six.mp4',
         audioURL: 'rndaudio/six.wav',
         bookURL: 'book6'
       }
     });

     this.rndUpdatesData.push({
       id: 'R & D Update 7', title: 'R & D Update 7', alertLevel: 'orange', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 8', title: 'R & D Update 8', alertLevel: 'orange', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 9', title: 'R & D Update 9', alertLevel: 'orange', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 10', title: 'R & D Update 10', alertLevel: 'green', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 11', title: 'R & D Update 11', alertLevel: 'green', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 12', title: 'R & D Update 12', alertLevel: 'green', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 14', title: 'R & D Update 13', alertLevel: 'green', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
     this.rndUpdatesData.push({
       id: 'R & D Update 15', title: 'R & D Update 14', alertLevel: 'red', readAvailable: true,
       listenAvailable: true, videoAvailable: true, readTime: '5 min*', listenTime: '8 min*', watchTime: '10 min*',
       lastreadPage: 1, lastlistenTime: 0, lastwatchTime: 0,
       content: {
         videoURL: '../../../assets/layout/videos/one.mp4',
         audioURL: '../../../assets/layout/videos/one.mp4',
         bookID: 'book1'
       }
     });
*/
/*
      return this.afs.collection('rndupdates', ref => ref.where('category','array-contains-any', cateogories)
      .orderBy("createdAt", 'desc'))
          */
      return this.afs.collection('rndupdates', ref => ref
          .orderBy("createdAt", 'desc'))
        .snapshotChanges()
        .pipe(
          map(snaps => convertSnaps<RndUpdateModel>(snaps)),
          first());
    }
}


  loadAllRndUpdates() {

    return this.afs.collection('rndupdates', ref => ref.orderBy('seqNo'))
        .snapshotChanges()
        .pipe(
            map(snaps => convertSnaps<RndUpdateModel>(snaps)),
            first())
  }

  loadCourses() {

    return this.afs.collection('courses', ref => ref.orderBy('seqNo'))
        .snapshotChanges()
        .pipe(
            map(snaps => convertSnaps<CourseModel>(snaps)),
            first())
  }

  loadACourse(courseId:string) {
      console.log("loading course with courseId:" + courseId);
    let path = "courses/" + courseId;
    return this.afs.doc(path).get();

  }


  loadCourseTopics(courseId:string) {
     const path = '/courses/' + courseId + '/topics';
     console.log("path:" + path);
     return this.afs.collection(path, ref => ref.orderBy('seqNoInCourse'))
         .snapshotChanges()
         .pipe(
             map(snaps => convertSnaps<TopicModel>(snaps)),
             first())
   }


  loadDiscussions(courseId:string) {
    const path =  '/courses/' + courseId + '/discussions';
    return this.afs.collection(path)
        .snapshotChanges()
        .pipe(
            map(snaps => convertSnaps<DiscussionModel>(snaps)),
            first())
  }

  saveRndUpdate(changes:Partial<RndUpdateModel>) {
    const path = 'rndupdates';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
}

  saveSolutionsPaper(changes:Partial<SolutionsPaperModel>) {
    const path = 'solutionspapers';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  saveCourse(changes:Partial<CourseModel>) {
    const path = 'courses';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  saveTopic(changes:Partial<TopicModel>, courseId:string){
    const path = 'courses/' + courseId + '/topics';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  addQuestion(changes:Partial<QaModel>, courseId:string, topicId:string=""){
    const path = 'courses/' + courseId +  '/qas';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  addAnswer(changes:Partial<QaModel>, courseId:string, topicId:string=""){
    const path = 'courses/' + courseId + '/qas';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  addDiscussion(changes:Partial<DiscussionModel>, courseId:string){
      let path = 'courses/' + courseId +  '/discussions';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
 0 }

  saveDiscussionThread(changes:Partial<ThreadModel>, courseId:string, discussionId:string){
    let path = 'courses/' + courseId +'/discussions/' + discussionId + '/threads';
    const colRef: AngularFirestoreCollection<any> = this.afs.collection(path);
    return colRef.add(changes);
  }

  loadSolutionsPapers(category, pageNumber): Observable<SolutionsPaperModel[]> {
    if (category === "Orange") {
      return this.afs.collection('solutionspapers', ref => ref.where("alertLevel", "==", "Orange")
        .orderBy("createdAt", 'desc')
      )
        .snapshotChanges()
        .pipe(
          map(snaps => convertSnaps<SolutionsPaperModel>(snaps)),
          first())    }
    else if (category === "Green") {
      return this.afs.collection('solutionspapers', ref => ref.where("alertLevel", "==", "Green")
        .orderBy("createdAt", 'desc')
      )
        .snapshotChanges()
        .pipe(
          map(snaps => convertSnaps<SolutionsPaperModel>(snaps)),
          first())
    }
    else {
      return this.afs.collection('solutionspapers', ref => ref.orderBy("createdAt", 'desc'))
        .snapshotChanges()
        .pipe(
          map(snaps => convertSnaps<SolutionsPaperModel>(snaps)),
          first())
    }
  }

  loadPlaylist(listType) {

    if (listType.includes('rnd')) {
      if (listType.includes('audio') && this.rndUpdatesPlaylist) return of(this.rndUpdatesPlaylist.map((item) => item.content.audioURL));
      else if (listType.includes('video') && this.rndUpdatesPlaylist)
        return of(this.rndUpdatesPlaylist.map((item) => item.content.videoURL));
      else {
        // load rndplaylist from database
      }
    } else if (listType.includes('solutions')) {
      if (listType.includes('audio') && this.solutionsPapersPlaylist) return of(this.solutionsPapersPlaylist.map((item) => item.content.audioURL));
      else if (listType.includes('video') && this.solutionsPapersPlaylist)
        return of(this.solutionsPapersPlaylist.map((item) => item.content.videoURL));
    }
    else {
      // load solutionsplaylist from database
    }
  }

  savePlaylist(listType,userID) {
    // save playlist to database

    const path = 'users/' + userID;
    const docRef: AngularFirestoreDocument = this.afs.doc(path);
    return docRef.set({rndPlaylist:this.rndUpdatesPlaylist});
  }

  addToPlaylist(listType, listItem) {
    if (listType.includes('rnd')) {
      if (listType.includes('audio')) this.rndUpdatesPlaylist.push(listItem);
      else if (listType.includes('video'))
        this.rndUpdatesPlaylist.push(listItem);
    } else if (listType.includes('solutions')) {
      if (listType.includes('audio')) this.rndUpdatesPlaylist.push(listItem);
      else if (listType.includes('video'))
        this.rndUpdatesPlaylist.push(listItem);
    }
  }

 removeFromPlaylist(listType, listItem) {}

  loadBook(id) {
    let path = "books/" + id;
    return this.afs.doc(path).get();

  }

  saveBook(changes) {
    return this.afs.collection("books").add(changes);
  }

  loadDiscussionThreads(courseId, topicId="", discussionId) {
    let path =  '/courses/' + courseId +  '/discussions/' + discussionId + "/threads";
    return this.afs.collection(path)
        .snapshotChanges()
        .pipe(
            map(snaps => convertSnaps<ThreadModel>(snaps)),
            first())
  }


    loadCourseQAs(courseId: string) {
      const path =  '/courses/' + courseId + '/qas';
      return this.afs.collection(path, ref => ref.orderBy("createdAt", 'desc'))
          .snapshotChanges()
          .pipe(
              map(snaps => convertSnaps<QaModel>(snaps)),
              first())
    }
}

